<template>
  <div ref="cardBase" class="card-base" :data-size="cardSize" @click="clickGame">
    <div class="card-base__preview" />
    <atomic-image class="card-base__img" :src="src" />
    <div class="card-base__overlay" />

    <div v-if="!isMobile" class="card-base__info">
      <div class="btn-play">
        <div class="btn-play__overlay-hover" />
        <div class="btn-play__overlay" />

        <atomic-icon id="play" />
      </div>

      <button-base
        v-if="props.gameInfo?.isDemoMode"
        class="btn-try"
        tag-name="span"
        type="secondary-2"
        size="xs"
        @click.stop="openGame(false)"
      >
        Demo
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { IGame } from '~/types';

  const props = defineProps<{
    gameInfo?: IGame;
  }>();

  const router = useRouter();
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { localizePath, getImageUrl } = useProjectMethods();

  const openGame = (isReal: boolean): void => {
    if (!props.gameInfo) return;

    if (props.gameInfo?.identity === 'betsy-sportsbook-betsy') {
      router.push(localizePath('/sports'));
    } else if (!isReal) {
      router.push(localizePath(`/games/${props.gameInfo?.identity}`));
    } else if (!isLoggedIn.value) {
      router.push(localizePath('/sign-up'));
    } else {
      router.push(localizePath(`/games/${props.gameInfo?.identity}?real=true`));
    }
  };

  const src = computed(() => {
    if (props.gameInfo?.images?.hasOwnProperty('200x300')) {
      return getImageUrl(props.gameInfo.images, 'vertical');
    }

    return '';
  });

  const globalStore = useGlobalStore();
  const { isMobile } = storeToRefs(globalStore);
  const clickGame = async (): Promise<void> => {
    if (!props.gameInfo) return;
    if (!isMobile.value) {
      openGame(true);
      return;
    }

    if (props.gameInfo.identity === 'betsy-sportsbook-betsy') {
      await router.push(localizePath('/sports'));
    } else {
      const { openGame } = useMobileGameLogic(props.gameInfo);
      await openGame();
    }
  };

  const cardBase = ref<HTMLElement>();
  const cardSize = ref<string>('');
  const timeoutId = ref();
  const cardSizes = [
    { size: 'min', min: 104, max: 127 },
    { size: 'def', min: 128, max: 167 },
    { size: 'max', min: 168, max: 200 },
  ];

  const setCardSize = () => {
    const width = cardBase.value?.getBoundingClientRect().width || 0;

    cardSize.value = cardSizes.reduce((acc, { size, min, max }) => {
      if (width > max) acc = 'max';
      if (width >= min && width <= max) acc = size;
      return acc;
    }, 'min');
  };

  const onResize = () => {
    clearTimeout(timeoutId.value);
    timeoutId.value = setTimeout(setCardSize, 200);
  };

  onMounted(() => {
    window.addEventListener('resize', onResize);
    setCardSize();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResize);
  });
</script>

<style src="~/assets/styles/components/card/base.scss" lang="scss" />
